import UiIconNames from "~/components/ui/icon/UiIconNames";

export const PhoneNumber = {
  value: '+7 (383) 286-82-50',
  link: 'tel:83832868250',
}

export const FreePhoneNumber = {
  value: '+7 (800) 250-13-40',
  link: 'tel:88002501340',
}

export const WhatsAppNumber = {
  value: '+7 (913) 958-27-24',
  link: 'https://wa.me/79139582724',
}

export const MailAddress = {
  value: 'sales@nposys.ru',
  link: 'mailto:sales@nposys.ru',
  subjectLink: 'mailto:sales@nposys.ru?subject=Заказ консультации по ТендерМонитору',
  orderLink: 'mailto:sales@nposys.ru?subject=Заказ консультации по оказываемым услугам',
  link403: 'mailto:sales@nposys.ru?subject=Консультация по подключенным тарифным опциям',
  link404: 'mailto:sales@nposys.ru?subject=Консультация по ТендерМонитору - переход по неисправной ссылке',
}

export const ConnectWithUs = [
  { label: "vk", icon: UiIconNames.SocialMedia_Vkontakte, link: "https://vk.com/nposys2009" },
  { label: "telegram", icon: UiIconNames.SocialMedia_Telegram, link: "https://t.me/NPOSistem" },
  { label: "wa", icon: UiIconNames.SocialMedia_Whatsapp, link: "https://wa.me/79139582724" },
];
